.legals-title {
  font-size: 20px;
  font-weight: 600;
  color: #0b5846;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 15px; }
  @media (min-width: 640px) {
    .legals-title {
      font-size: 27px; } }

.legals-container {
  padding-left: 15px;
  padding-right: 15px;
  color: #073C2F;
  font-size: 13px; }
  @media (min-width: 375px) {
    .legals-container {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 640px) {
    .legals-container {
      padding-left: 60px;
      padding-right: 60px; } }
  .legals-container h2 {
    font-size: 22px;
    padding-top: 15px;
    padding-bottom: 6px; }

dt {
  font-weight: 500; }
