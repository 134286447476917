.home-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0 15px; }
  @media (min-width: 375px) {
    .home-section {
      padding: 0 30px; } }
  @media (min-width: 640px) {
    .home-section {
      padding: 0; } }
  @media (min-width: 960px) {
    .home-section {
      -ms-flex-direction: row;
          flex-direction: row;
      margin-top: 60px; } }
  .home-section:nth-child(2n + 2) {
    margin-left: -25%;
    margin-right: -25%;
    padding-left: 25%;
    padding-right: 25%;
    background-color: #f5f5f5; }
  .home-section:nth-child(odd) .square-item {
    background-color: transparent; }

.home__aepk--banner {
  height: 260px;
  background-image: 'url(/images/homepage-2500x408.jpg)';
  background-size: cover;
  background-position: center; }

#home-groupement {
  -ms-flex-order: 1;
      order: 1;
  padding-right: 30px;
  padding-left: 30px; }
  @media (min-width: 640px) {
    #home-groupement {
      padding-right: 0; } }
  @media (min-width: 960px) {
    #home-groupement {
      -ms-flex-order: 2;
          order: 2;
      padding-left: 60px; } }

#home-groupement-grid {
  -ms-flex-order: 2;
      order: 2;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @supports ((display: -ms-grid) or (display: grid)) {
    #home-groupement-grid {
      display: -ms-grid;
      display: grid;
          -ms-grid-columns: 1fr 15px 1fr;
          grid-template-columns: 1fr 1fr;
          -ms-grid-rows: 1fr 15px 1fr;
          grid-template-rows: 1fr 1fr;
      grid-gap: 15px;
          grid-template-areas: '. .' '. .';
      grid-auto-rows: 1fr; } }
  #home-groupement-grid .square-item {
    width: 140px;
    height: 140px; }
    @media (min-width: 640px) {
      #home-groupement-grid .square-item {
        width: 200px;
        height: 200px; } }
    #home-groupement-grid .square-item img {
      padding-bottom: 0px;
      max-width: 60px; }
      @media (min-width: 640px) {
        #home-groupement-grid .square-item img {
          max-width: 100px; } }
    #home-groupement-grid .square-item .square-item__title {
      font-size: 16px; }
      @media (min-width: 640px) {
        #home-groupement-grid .square-item .square-item__title {
          font-size: 20px; } }
    #home-groupement-grid .square-item .square-item__subtitle {
      font-size: 11px;
      line-height: 1.1; }
      @media (min-width: 640px) {
        #home-groupement-grid .square-item .square-item__subtitle {
          font-size: 16px;
          line-height: unset; } }
  @media (min-width: 960px) {
    #home-groupement-grid {
      -ms-flex-order: 1;
          order: 1; }
      @supports ((display: -ms-grid) or (display: grid)) {
        #home-groupement-grid {
          padding-top: 100px; }
          #home-groupement-grid > *:nth-last-child(2n-1) .square-item__content {
            -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                    transform: translateY(-50%); } } }

.home-advices--container {
  padding: 60px; }

#home-advices {
  -ms-flex-order: 1;
      order: 1;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  padding-right: 30px; }
  @media (min-width: 640px) {
    #home-advices {
      padding-right: 0; } }
  @media (min-width: 960px) {
    #home-advices {
      -ms-flex-order: 1;
          order: 1;
      padding-left: 60px;
      padding-right: 60px; } }

#home-advices-list {
  -ms-flex-order: 2;
      order: 2;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (min-width: 960px) {
    #home-advices-list {
      -ms-flex-order: 2;
          order: 2; } }
  #home-advices-list > :not(:last-child) {
    margin-bottom: 15px; }
  #home-advices-list > :nth-child(even) .advice-item__title {
    color: #f5a800; }
  #home-advices-list > :nth-child(odd) .advice-item__title {
    color: #48c99b; }
  #home-advices-list > :nth-child(even) .jam {
    border-color: transparent transparent #f5a800 transparent; }
  #home-advices-list > :nth-child(odd) .jam {
    border-color: transparent transparent #48c99b transparent; }
  #home-advices-list .advice-item__image {
    width: 130px;
    height: 130px; }
  #home-advices-list .advice-item__informations {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0 15px;
    min-height: 130px;
    width: calc(100% - 130px); }
  #home-advices-list .advice-item__category {
    display: none; }
  #home-advices-list .advice-item__content {
    display: -ms-flexbox;
    display: flex; }
    #home-advices-list .advice-item__content h3 {
      font-size: 13px; }
      @media (min-width: 640px) {
        #home-advices-list .advice-item__content h3 {
          font-size: 16px; } }
