.square-item {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff; }
  .square-item .square-item__content {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px 0px #beecdc;
            box-shadow: 0px 0px 10px 0px #beecdc;
    color: #48c99b;
    background: #ffffff;
    width: 100%;
    height: 100%; }
  .square-item.square-item--highlighted .square-item__content {
    color: #ffffff;
    background: #48c99b; }
  .square-item img {
    max-width: 60px;
    padding-bottom: 15px; }
  .square-item .square-item__title {
    font-weight: 600;
    padding-bottom: 3px;
    text-transform: uppercase; }
  .square-item .square-item__subtitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px; }

@media (min-width: 960px) {
  .square-item {
    width: 200px;
    height: 200px; } }
