.home-block {
  padding-top: 60px;
  padding-bottom: 30px; }
  @media (min-width: 960px) {
    .home-block {
      padding-bottom: 0; } }
  .home-block .home-block__index {
    font-size: 58px;
    color: #0b5846;
    font-weight: 600;
    position: relative;
    margin-bottom: 45px;
    letter-spacing: 5px;
    display: none; }
    @media (min-width: 640px) {
      .home-block .home-block__index {
        display: block; } }
    .home-block .home-block__index::after {
      content: '';
      position: absolute;
      height: 30px;
      width: 3px;
      background: #0b5846;
      left: 3px;
      bottom: -30px; }
  .home-block .home-block__title {
    font-size: 20px;
    font-weight: 600;
    color: #0b5846;
    text-transform: uppercase;
    text-align: left; }
    @media (min-width: 640px) {
      .home-block .home-block__title {
        font-size: 27px; } }
  .home-block .home-block__subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #48c99b;
    text-transform: uppercase; }
    @media (min-width: 640px) {
      .home-block .home-block__subtitle {
        font-size: 20px; } }
  .home-block .home-block__description {
    font-size: 13px;
    font-weight: 500;
    color: #073C2F;
    margin: 15px 0; }
    @media (min-width: 960px) {
      .home-block .home-block__description {
        margin: 30px 0; } }

@media (min-width: 960px) {
  > div {
    padding-left: 60px; } }
