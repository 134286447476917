.advice-item-grid {
  margin: auto;
  padding: 0 30px 30px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  -ms-grid-columns: repeat(auto-fill, minmax(50%, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 30px; }
  .advice-item-grid > *:nth-child(even) .advice-item__title {
    color: #0b5846; }
  .advice-item-grid > *:nth-child(odd) .advice-item__title {
    color: #48c99b; }
  .advice-item-grid > *:nth-child(even) .jam {
    border-color: transparent transparent #0b5846 transparent; }
  .advice-item-grid > *:nth-child(odd) .jam {
    border-color: transparent transparent #48c99b transparent; }

.advice-item-grid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  -ms-grid-row: 1;
  grid-row: 1 / 1;
  -ms-grid-column: 1;
  grid-column: 1 / 1; }

.advice-item-grid > *:first-child {
  -ms-grid-row: 1;
  grid-row: 1 / 1;
  -ms-grid-column: 1;
  grid-column: 1 / 1; }

.advice-item-grid > *,
.home__module__03 > * {
  display: block;
  position: relative;
  background-color: #ffffff; }
  .advice-item-grid > * .advice-item,
  .home__module__03 > * .advice-item {
    height: 100%; }

@media (min-width: 640px) {
  .advice-item-grid {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr)); } }

@media (min-width: 960px) {
  .advice-item-grid {
    padding-bottom: 0;
    grid-template-columns: repeat(auto-fill, minmax(26%, 1fr)); } }
