.group-container {
  margin-bottom: 60px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 30px 30px; }
  @media (min-width: 640px) {
    .group-container {
      padding: 0; } }
  .group-container .home-block {
    padding: 0; }
    .group-container .home-block .home-block__description {
      font-size: 13px; }

.group-solidarity {
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (min-width: 640px) {
    .group-solidarity {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -webkit-box-sizing: unset;
              box-sizing: unset; } }
  .group-solidarity > * {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1; }
  .group-solidarity article {
    height: 350px;
    -ms-flex-pack: center;
        justify-content: center; }
    .group-solidarity article .group__solidarity__image {
      background-image: url("/images/association-image-intro.jpg");
      -webkit-box-shadow: 0px 0px 10px 0px gainsboro;
              box-shadow: 0px 0px 10px 0px gainsboro;
      max-width: 334px;
      height: 350px;
      width: 100%;
      background-size: cover;
      background-position: center center; }
      @media (min-width: 640px) {
        .group-solidarity article .group__solidarity__image {
          margin-left: 60px;
          height: 100%; } }
      @media (min-width: 960px) {
        .group-solidarity article .group__solidarity__image {
          margin-top: 10px;
          height: 350px; } }
    @media (min-width: 640px) {
      .group-solidarity article {
        -ms-flex-pack: right;
            justify-content: right;
        padding-top: 15px;
        padding-bottom: 15px; } }

.group__reasons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (min-width: 960px) {
    .group__reasons {
      -ms-flex-direction: row;
          flex-direction: row; } }
  .group__reasons .squares {
    -ms-flex-order: 2;
        order: 2;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @supports ((display: -ms-grid) or (display: grid)) {
      .group__reasons .squares {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr; } }
    .group__reasons .squares .square-item {
      width: 140px;
      height: 140px; }
      @media (min-width: 640px) {
        .group__reasons .squares .square-item {
          width: 200px;
          height: 200px; } }
      .group__reasons .squares .square-item img {
        padding-bottom: 0px; }
        @media (min-width: 640px) {
          .group__reasons .squares .square-item img {
            padding-bottom: 15px; } }
        @media (min-width: 640px) {
          .group__reasons .squares .square-item img {
            max-width: 70px; } }
      .group__reasons .squares .square-item .square-item__title {
        font-size: 16px; }
      .group__reasons .squares .square-item .square-item-text__title.square-item__title {
        font-size: 30px; }
      .group__reasons .squares .square-item .square-item__subtitle {
        font-size: 11px;
        line-height: 1.1; }
        @media (min-width: 640px) {
          .group__reasons .squares .square-item .square-item__subtitle {
            font-size: 13px;
            line-height: unset; } }
    @media (min-width: 740px) {
      .group__reasons .squares {
        -ms-flex-order: 1;
            order: 1;
        margin: auto;
        margin-left: 0;
        -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 0;
        grid-column-gap: 0; } }
  .group__reasons .group__reasons__text {
    -ms-flex-order: 1;
        order: 1; }
    @media (min-width: 960px) {
      .group__reasons .group__reasons__text {
        -ms-flex-order: 2;
            order: 2;
        padding-left: 30px;
        -ms-flex-align: center;
            align-items: center; } }
    @media (min-width: 1280px) {
      .group__reasons .group__reasons__text {
        padding-left: 60px; } }

.group__also {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: 100%;
  padding-bottom: 15px; }
  @supports ((display: -ms-grid) or (display: grid)) {
    .group__also {
      display: -ms-grid;
      display: grid;
          -ms-grid-columns: 1fr;
          grid-template-columns: 1fr;
          -ms-grid-rows: 1fr 30px 1fr 30px 1fr;
          grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 30px;
          grid-template-areas: '.' '.' '.'; }
      @media (min-width: 640px) {
        .group__also {
          -ms-grid-columns: 1fr 1fr;
              grid-template-columns: 1fr 1fr;
          -ms-grid-rows: 1fr 1fr;
              grid-template-rows: 1fr 1fr; } }
      @media (min-width: 960px) {
        .group__also {
              -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
              grid-template-columns: 1fr 1fr 1fr;
              -ms-grid-rows: 1fr;
              grid-template-rows: 1fr;
              grid-template-areas: '. . .'; } } }
