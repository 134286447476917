.header-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background: #0b5846;
  overflow: hidden;
  min-height: 97px; }
  .header-top .header-top__logo {
    overflow: hidden;
    height: 97px;
    position: relative;
    position: -ms-device-fixed; }
    @media (min-width: 960px) {
      .header-top .header-top__logo {
        position: relative;
        -ms-flex-pack: center;
            justify-content: center;
        min-width: 340px;
        -ms-flex-order: 1;
            order: 1; } }
    .header-top .header-top__logo #logo-header {
      display: block;
      height: 97px;
      background-image: url("/images/logo-aepk.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }

.header-top__left {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 97px;
  left: 0;
  -ms-flex-order: 1;
      order: 1;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 63px;
  font-weight: 500;
  background-color: #48c99b; }
  @media (min-width: 960px) {
    .header-top__left {
      display: none;
      position: relative;
      top: 0;
      left: 0; } }
  .header-top__left .header-top__toggle {
    position: absolute;
    left: 0;
    top: 10px;
    font-size: 40px;
    line-height: 40px;
    color: #ffffff;
    background: 0;
    border: 0;
    cursor: pointer;
    z-index: 1; }
    .header-top__left .header-top__toggle--open {
      color: #0b5846; }
  .header-top__left a {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    color: #ffffff;
    letter-spacing: 2px;
    -ms-flex-align: center;
        align-items: center;
    font-size: 15px; }
  .header-top__left a > span.jam {
    font-size: 20px;
    padding-right: 6px; }

.header-top__right {
  display: none; }

@media (min-width: 960px) {
  .header-top {
    padding: 0 15px;
    -ms-flex-direction: row;
        flex-direction: row; }
    .header-top .header-top__left {
      max-width: 300px;
      -ms-flex-pack: start;
          justify-content: flex-start;
      background-color: transparent;
      height: 100%;
      -ms-flex-order: 0;
          order: 0; }
      .header-top .header-top__left .header-top__toggle {
        display: none; }
      .header-top .header-top__left a {
        border-right: 1px solid #073C2F;
        padding: 0 30px 0 15px;
        height: 97px;
        font-size: 13px; }
      .header-top .header-top__left a > span.jam {
        font-size: 24px;
        padding-right: 15px; }
    .header-top .header-top__right {
      min-width: 400px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-order: 2;
          order: 2;
      -ms-flex-pack: end;
          justify-content: flex-end;
      -ms-flex-align: center;
          align-items: center; } }
