.partner-item-grid {
  margin: auto;
  padding: 0 30px 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @supports ((display: -ms-grid) or (display: grid)) {
    .partner-item-grid {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(34%, 1fr));
      -ms-grid-columns: repeat(auto-fill, minmax(50%, 1fr));
      grid-auto-rows: 1fr;
      grid-gap: 30px; } }
  @media (min-width: 640px) {
    .partner-item-grid {
      padding: 0;
      grid-template-columns: repeat(auto-fill, minmax(22%, 1fr)); } }
  @media (min-width: 960px) {
    .partner-item-grid {
      grid-template-columns: repeat(auto-fill, minmax(22%, 1fr)); } }
  .partner-item-grid > * {
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 10px 0px gainsboro;
            box-shadow: 0px 0px 10px 0px gainsboro;
    min-width: 120px;
    min-height: 120px; }
  @supports ((display: -ms-grid) or (display: grid)) {
    .partner-item-grid::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      -ms-grid-row: 1;
      grid-row: 1 / 1;
      -ms-grid-column: 1;
      grid-column: 1 / 1; }
    .partner-item-grid > *:first-child {
      -ms-grid-row: 1;
      grid-row: 1 / 1;
      -ms-grid-column: 1;
      grid-column: 1 / 1; } }
  .partner-item-grid:nth-child(even) .partner-item__overlay {
    background: #0b5846; }
    .partner-item-grid:nth-child(even) .partner-item__overlay span {
      color: #00c07c; }
  .partner-item-grid:nth-child(odd) .partner-item__overlay {
    background: #0b5846; }
    .partner-item-grid:nth-child(odd) .partner-item__overlay span {
      color: #48c99b; }
