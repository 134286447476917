.footer__links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 75px 0 10px 0;
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: #1ce0b2; }
  .footer__links h3 {
    text-transform: uppercase;
    padding: 15px 0;
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 20px; }
  .footer__links li {
    padding: 6px 0; }
  .footer__links a {
    color: #1ce0b2; }
    .footer__links a:hover {
      color: #ffffff; }
  .footer__links section:first-of-type {
    display: none; }
  .footer__links #logo-footer {
    width: 100%;
    height: 50px;
    background-image: url("/images/logo-aepk.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

@media (min-width: 960px) {
  .footer__links {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
    margin: 0 auto;
    width: 80%;
    padding-top: 40px;
    padding-bottom: 10px; }
    .footer__links section {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1;
          flex: 1;
      -ms-flex-direction: column;
          flex-direction: column;
      text-align: center;
      padding: 0 15px; }
    .footer__links section:first-of-type {
      display: -ms-flexbox;
      display: flex; } }

#logo-valwin {
  height: 70px;
  width: 100%;
  background-image: url("/images/valwin.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }
