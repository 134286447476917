.btn {
  display: block;
  width: 180px;
  height: 40px;
  line-height: 42px;
  border: 0;
  outline: none;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  outline-style: none; }
  .btn.btn--small {
    padding: 0 15px;
    width: auto; }
  .btn.btn--turquoise {
    background-color: #48c99b; }
  .btn.btn--turquoise:hover {
    background-color: #00c07c; }
  .btn.btn--primary {
    background-color: #f5a800; }
  .btn.btn--primary:hover {
    background-color: #f58400; }
  .btn.btn--blue {
    background-color: #073C2F; }
  .btn.btn--blue:hover {
    background-color: #005479; }
