.group-image-hover {
  position: relative;
  min-height: 400px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px gainsboro;
          box-shadow: 0px 0px 10px 0px gainsboro;
  background-color: #ffffff; }
  .group-image-hover .group-image-hover__image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .group-image-hover .group-image-hover__content {
    background: #ffffff;
    padding: 30px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .group-image-hover .group-image-hover__title {
    color: #48c99b;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px; }
  .group-image-hover .group-image-hover__text {
    color: #0b5846; }
  .group-image-hover span.jam {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 45px 45px;
    border-color: transparent transparent #48c99b transparent; }
    @media (min-width: 960px) {
      .group-image-hover span.jam {
        display: block; } }
    .group-image-hover span.jam::before {
      position: absolute;
      top: 22.5px;
      right: 6px;
      color: #ffffff;
      font-weight: 500; }

@media (min-width: 960px) {
  .group-image-hover .group-image-hover__block {
    position: absolute;
    -webkit-transition: top 0.4s ease;
    -o-transition: top 0.4s ease;
    transition: top 0.4s ease;
    height: 200%;
    width: 100%;
    top: 0; }
  .group-image-hover .group-image-hover__content {
    height: 400px;
    width: 100%; }
  .group-image-hover .group-image-hover__image {
    width: 100%;
    height: 400px; }
  .group-image-hover:hover .group-image-hover__block {
    top: -100%; } }
