.advice-item {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 20px 0px gainsboro;
          box-shadow: 0px 0px 20px 0px gainsboro; }
  .advice-item .advice-item__image {
    height: calc(100% - 160px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .advice-item .advice-item__informations {
    padding: 30px;
    font-weight: 400;
    min-height: 160px; }
    .advice-item .advice-item__informations .advice-item__meta {
      display: block;
      color: #0b5846;
      font-size: 13px;
      font-weight: 300;
      margin-bottom: 15px; }
    .advice-item .advice-item__informations .advice-item__date {
      text-transform: capitalize; }
    .advice-item .advice-item__informations .advice-item__title {
      text-transform: uppercase;
      font-size: 16px; }
  .advice-item .advice-item__category {
    position: absolute;
    top: 30px;
    left: -15px;
    font-size: 13px;
    background: #0b5846;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    font-weight: 300;
    color: #ffffff; }
  .advice-item .advice-item__content {
    height: 100%;
    width: 100%; }
  .advice-item .jam {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 45px 45px; }
    .advice-item .jam::before {
      position: absolute;
      top: 22.5px;
      right: 6px;
      color: #ffffff;
      font-weight: 500; }
    @media (min-width: 960px) {
      .advice-item .jam {
        display: block; } }
