.footer {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  background: #0b5846;
  margin-top: 60px; }
  .footer .footer__copy {
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    padding: 6px 0;
    background: #073C2f;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    color: #1ce0b2; }
    .footer .footer__copy a {
      color: #1ce0b2; }
      .footer .footer__copy a:hover {
        color: #ffffff; }

.home + .footer {
  margin-top: 0; }
