.input__group {
  display: block;
  padding: 0; }
  .input__group input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    z-index: -666; }
  .input__group .input__group__label {
    display: block;
    text-transform: uppercase;
    color: #0b5846;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 40px; }
  .input__group .input__group__field {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: left;
        justify-content: left;
    -ms-flex-align: center;
        align-items: center;
    font-size: 13px;
    height: 45px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 15px;
    border: 0;
    border-radius: 1px;
    -webkit-box-shadow: 0px 0px 6px 0px gainsboro;
            box-shadow: 0px 0px 6px 0px gainsboro;
    width: 100%;
    color: #0b5846;
    font-weight: 400;
    overflow: hidden; }
    .input__group .input__group__field div.input__group__field__button {
      outline: 0;
      border: 1px solid #0b5846;
      background: #ffffff;
      color: #0b5846;
      margin-right: 15px;
      padding: 3px 6px;
      text-align: center;
      cursor: pointer; }
    .input__group .input__group__field.input__group__field__error {
      -webkit-box-shadow: 0px 0px 6px 0px #fdb7bb;
              box-shadow: 0px 0px 6px 0px #fdb7bb; }
  .input__group small {
    color: #e20613;
    text-transform: initial;
    font-style: italic; }
    .input__group small.input__group__label__error {
      margin-left: 6px; }
    .input__group small.input__group__label__required {
      margin-left: 0;
      font-weight: 700;
      font-size: 20px; }
  .input__group .input__group__label__required {
    display: none; }
    .input__group .input__group__label__required.input__group__label__required--show {
      display: inline-block; }
