.home-operations-slider {
  display: block;
  position: relative; }
  .home-operations-slider .slider-item * > div {
    height: 150px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat; }
    @media (min-width: 640px) {
      .home-operations-slider .slider-item * > div {
        height: 250px; } }
    @media (min-width: 960px) {
      .home-operations-slider .slider-item * > div {
        height: 350px; } }
  .home-operations-slider .home-operations-slider__carousel {
    position: relative;
    min-height: 150px -3px;
    overflow: hidden; }
    @media (min-width: 640px) {
      .home-operations-slider .home-operations-slider__carousel {
        min-height: 250px -3px; } }
    @media (min-width: 960px) {
      .home-operations-slider .home-operations-slider__carousel {
        min-height: 350px -3px; } }
  .home-operations-slider .home-operations-slider__arrows {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 100%;
    z-index: 999;
    pointer-events: none; }
    .home-operations-slider .home-operations-slider__arrows button {
      outline: none;
      border: 0;
      background: transparent;
      color: #0b5846;
      font-size: 40px;
      cursor: pointer;
      pointer-events: all; }
      .home-operations-slider .home-operations-slider__arrows button[disabled='disabled'], .home-operations-slider .home-operations-slider__arrows button:disabled {
        opacity: 0.2;
        cursor: initial; }
      .home-operations-slider .home-operations-slider__arrows button.home-operations-slider__arrows--next {
        float: right; }
      .home-operations-slider .home-operations-slider__arrows button.home-operations-slider__arrows--previous {
        float: left; }
  .home-operations-slider .slick-dots {
    width: 100%;
    position: absolute;
    bottom: 6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center; }
    .home-operations-slider .slick-dots li {
      display: inline-block;
      margin-right: 6px; }
      .home-operations-slider .slick-dots li.slick-active button {
        background: #0b5846; }
      .home-operations-slider .slick-dots li button {
        cursor: pointer;
        background: transparent;
        width: 12px;
        height: 12px;
        border: 2px solid #0b5846;
        border-radius: 50%;
        font-size: 0; }
