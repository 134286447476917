.header-bottom {
  display: block;
  position: absolute;
  background: #f5f5f5;
  overflow: hidden;
  padding: 30px 0 60px 0;
  width: 0; }
  @media (min-width: 960px) {
    .header-bottom {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      background: #ffffff;
      height: 63px;
      max-width: 100%;
      width: 100%;
      padding: 0;
      -webkit-box-shadow: 0px 4px 8px 0px gainsboro;
              box-shadow: 0px 4px 8px 0px gainsboro;
      margin-bottom: 15px; } }
  .header-bottom.header-bottom--open {
    width: 100%; }
  .header-bottom.header-bottom--activated {
    -webkit-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s; }
  .header-bottom .header-bottom__menu {
    display: block;
    width: 180px;
    margin: auto; }
    @media (min-width: 960px) {
      .header-bottom .header-bottom__menu {
        height: 63px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
        width: 100%; } }
    .header-bottom .header-bottom__menu a {
      display: block;
      color: #3c3c3b;
      height: 100%;
      width: 100%; }
      .header-bottom .header-bottom__menu a.btn {
        display: block;
        float: right;
        margin: 0 0 15px 0;
        clear: both;
        color: #ffffff; }
        @media (min-width: 960px) {
          .header-bottom .header-bottom__menu a.btn {
            display: none; } }
    .header-bottom .header-bottom__menu .header-bottom__menu__item {
      display: block;
      position: relative;
      list-style: none;
      text-align: right;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 400; }
      .header-bottom .header-bottom__menu .header-bottom__menu__item a {
        padding: 15px 0; }
      @media (min-width: 960px) {
        .header-bottom .header-bottom__menu .header-bottom__menu__item {
          display: -ms-flexbox;
          display: flex;
          padding: 0 15px;
          list-style: none;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: center;
              justify-content: center;
          height: auto; }
          .header-bottom .header-bottom__menu .header-bottom__menu__item > a {
            text-transform: uppercase;
            font-size: 13px;
            color: #3c3c3b;
            letter-spacing: 1px;
            font-weight: 400; }
            .header-bottom .header-bottom__menu .header-bottom__menu__item > a.header-bottom__menu__item--active {
              color: #0b5846; } }
      .header-bottom .header-bottom__menu .header-bottom__menu__item:last-of-type {
        margin-bottom: 30px; }
        @media (min-width: 960px) {
          .header-bottom .header-bottom__menu .header-bottom__menu__item:last-of-type {
            margin-bottom: 0; } }
      .header-bottom .header-bottom__menu .header-bottom__menu__item:hover {
        color: #0b5846;
        font-weight: 700; }
        .header-bottom .header-bottom__menu .header-bottom__menu__item:hover:after {
          content: '';
          position: absolute;
          height: 2px;
          background: #0b5846;
          left: -666px;
          right: 0;
          bottom: 0; }
        .header-bottom .header-bottom__menu .header-bottom__menu__item:hover a {
          color: #0b5846; }
          .header-bottom .header-bottom__menu .header-bottom__menu__item:hover a.header-bottom__menu__item--active {
            color: #0b5846; }
        @media (min-width: 960px) {
          .header-bottom .header-bottom__menu .header-bottom__menu__item:hover:after {
            display: none; }
          .header-bottom .header-bottom__menu .header-bottom__menu__item:hover:hover > a {
            font-weight: 700; } }
