.textarea__group {
  height: 100%;
  padding: 0; }
  .textarea__group .textarea__group__label {
    display: block;
    text-transform: uppercase;
    color: #0b5846;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 40px; }
  .textarea__group .textarea__group__field {
    height: calc(100% - 40px);
    font-size: 13px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 15px;
    border: 0;
    -webkit-box-shadow: 0px 0px 6px 0px gainsboro;
            box-shadow: 0px 0px 6px 0px gainsboro;
    width: 100%;
    color: #0b5846;
    font-weight: 400;
    resize: none; }
