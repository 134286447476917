.partner-item__content {
  position: absolute;
  height: 200%;
  width: 100%;
  top: 0;
  -webkit-transition: top 0.4s ease;
  -o-transition: top 0.4s ease;
  transition: top 0.4s ease;
  background: #ffffff; }
  @media (min-width: 640px) {
    .partner-item__content:hover {
      top: -100%; } }

.partner-item__logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 50%; }
  .partner-item__logo img {
    max-width: 100%;
    min-width: 100px;
    min-height: 100px; }

.partner-item__overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 50%; }
  .partner-item__overlay h3 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 10px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 6px;
    text-align: center;
    font-size: 16px; }
  .partner-item__overlay span {
    font-weight: 400;
    font-size: 15px; }
  .partner-item__overlay span.jam {
    padding-top: 15px;
    color: #ffffff;
    font-size: 30px; }
