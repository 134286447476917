.conctactform form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  padding: 0 30px; }
  .conctactform form .textarea__group__field {
    min-height: 300px; }
  .conctactform form .contactform__footer {
    width: 100%; }
  .conctactform form .contactform__confirm {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin-top: 30px; }
  .conctactform form > * {
    padding: 15px 0; }
  .conctactform form .conctactform__button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .conctactform form .conctactform__button button {
      margin-top: 15px; }
  @media (min-width: 640px) {
    .conctactform form {
      padding: 0; } }

@media (min-width: 960px) {
  .conctactform form {
    display: -ms-grid;
    display: grid;
        -ms-grid-columns: 1fr 60px 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px 60px;
        grid-template-areas: 'conctactform__object conctactform__textarea' 'conctactform__name conctactform__textarea' 'conctactform__email conctactform__textarea' 'conctactform__phone conctactform__textarea' 'conctactform__file conctactform__textarea' 'conctactform__button conctactform__button'; }
    .conctactform form > * {
      padding: 0; }
  .conctactform__textarea {
    grid-area: conctactform__textarea;
    padding-right: 0;
    padding-left: 15px; }
  .conctactform__object {
    grid-area: conctactform__object; }
  .conctactform__name {
    grid-area: conctactform__name; }
  .conctactform__email {
    grid-area: conctactform__email; }
  .conctactform__phone {
    grid-area: conctactform__phone; }
  .conctactform__file {
    grid-area: conctactform__file; }
  .contactform__footer {
    grid-area: conctactform__button; }
  .contactform__confirm {
    color: #15cc5e; } }

@media (min-width: 960px) {
  .conctactform__textarea {
    -ms-grid-row: 1;
    -ms-grid-row-span: 9;
    -ms-grid-column: 3; }
  .conctactform__object {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .conctactform__name {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .conctactform__email {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .conctactform__phone {
    -ms-grid-row: 7;
    -ms-grid-column: 1; }
  .conctactform__file {
    -ms-grid-row: 9;
    -ms-grid-column: 1; }
  .contactform__footer {
    -ms-grid-row: 11;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3; } }
