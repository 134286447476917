.advice-filters {
  padding: 0 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .advice-filters button.btn {
    margin: auto;
    width: 100%; }
    .advice-filters button.btn.after {
      content: ''; }
  .advice-filters .advice-filters-list {
    position: absolute;
    background: #0b5846;
    max-height: 403px;
    overflow-y: auto;
    opacity: 0.9;
    left: 30px;
    z-index: 999;
    display: none;
    width: calc(100% - (30px*2));
    margin: auto; }
    @media (min-width: 640px) {
      .advice-filters .advice-filters-list {
        max-width: 360px; } }
    .advice-filters .advice-filters-list.advice-filters-list--show {
      display: block; }
    .advice-filters .advice-filters-list ul {
      width: 100%;
      padding: 30px 60px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
    .advice-filters .advice-filters-list li {
      text-align: left;
      padding: 3px 0; }
    .advice-filters .advice-filters-list input[type=checkbox] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      background: #0b5846;
      border: 2px solid #ffffff;
      height: 13px;
      width: 13px;
      cursor: pointer; }
    .advice-filters .advice-filters-list input[type=checkbox]:checked {
      background: #ffffff; }
    .advice-filters .advice-filters-list .advice-filters-reinit {
      text-align: left;
      font-size: 13px;
      display: none;
      margin-top: 15px;
      color: #0b5846;
      cursor: pointer; }
      .advice-filters .advice-filters-list .advice-filters-reinit.advice-filters-reinit--show {
        display: block; }
      .advice-filters .advice-filters-list .advice-filters-reinit:hover {
        color: #073C2F; }
    .advice-filters .advice-filters-list label {
      font-size: 13px;
      color: #ffffff;
      font-weight: 500;
      padding-left: 15px;
      cursor: pointer; }
  @media (min-width: 640px) {
    .advice-filters {
      width: 100%;
      padding: 0; }
      .advice-filters button.btn {
        width: 360px; }
      .advice-filters .advice-filters-list {
        width: 100%;
        padding: 0;
        right: 0;
        left: 0; }
      .advice-filters ul {
        width: 360px; } }
