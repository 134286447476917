body {
  background: white; }

.container {
  margin: auto; }
  @media (min-width: 640px) {
    .container {
      width: 85%; } }
  @media (min-width: 960px) {
    .container {
      width: 80%; } }
  @media (min-width: 1280px) {
    .container {
      width: 66.6%; } }

.gray-background {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 400px;
  z-index: -100;
  display: none; }
  @media (min-width: 640px) {
    .gray-background {
      display: block; } }
  .gray-background::before {
    content: '';
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 100%;
    width: 100%;
    z-index: -1f; }
  .gray-background.gray-background--top::before {
    top: 0;
    background-image: url("/images/gray-background--top.png"); }
  .gray-background.gray-background--bottom {
    height: 100px; }
    .gray-background.gray-background--bottom::before {
      background-image: url("/images/gray-background--bottom.png");
      bottom: calc(100% - 140px);
      height: 480px;
      background-size: contain; }
      @media (min-width: 1950px) {
        .gray-background.gray-background--bottom::before {
          display: none; } }
