body {
  padding-top: 160px; }

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; }
  .main-header .btn {
    margin-right: 15px; }
