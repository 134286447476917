.sitemap-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 375px) {
    .sitemap-container {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 640px) {
    .sitemap-container {
      padding-left: 60px;
      padding-right: 60px; } }
  .sitemap-container p {
    margin-top: 15px; }
  .sitemap-container a {
    color: #073C2F;
    font-size: 20px; }
    .sitemap-container a:hover {
      color: #0b5846; }
