.page-header {
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .page-header .page-header__button {
    position: relative;
    margin: 3px 7.5%;
    text-align: center; }
  .page-header .page-header__title {
    font-size: 28px;
    font-weight: 600;
    color: #0b5846;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin: 0; }
    @media (min-width: 640px) {
      .page-header .page-header__title {
        font-size: 35px; } }
    @media (min-width: 960px) {
      .page-header .page-header__title {
        font-size: 42px;
        margin-right: -5px; } }
  .page-header .page-header__subtitle {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #48c99b;
    padding: 15px; }
    @media (min-width: 640px) {
      .page-header .page-header__subtitle {
        padding: 15px 0;
        font-size: 22px; } }
    @media (min-width: 960px) {
      .page-header .page-header__subtitle {
        font-size: 24px; } }
  @media (min-width: 960px) {
    .page-header {
      position: relative;
      margin: 0 0 60px 0;
      padding: 60px 0 15px 0;
      max-width: 66.6%;
      border-bottom: 3px solid #0b5846;
      text-align: right; }
      .page-header.page-header--withaction {
        margin-bottom: 120px; }
      .page-header .page-header__title {
        font-size: 42px;
        margin-right: -5px; }
      .page-header .page-header__subtitle {
        font-size: 24px; }
      .page-header .page-header__button {
        position: absolute;
        margin: 0;
        right: 0;
        bottom: -70px; }
        .page-header .page-header__button button {
          margin-right: 0; }
      .page-header.page-header--right {
        padding-top: 0;
        margin-left: 33%;
        text-align: left; }
      .page-header.page-header--secondary {
        margin-top: 30px; } }
