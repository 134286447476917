.home-promotions-slider__carousel .slider-item * > div {
  margin-right: 15px;
  -webkit-box-shadow: 0px 0px 10px 0px gainsboro;
          box-shadow: 0px 0px 10px 0px gainsboro;
  height: 300px;
  width: 213px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 30px; }
  @media (min-width: 640px) {
    .home-promotions-slider__carousel .slider-item * > div {
      height: 400px;
      width: 284px; } }
