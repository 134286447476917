.home-promotions-slider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1;
  padding: 30px 0 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (min-width: 640px) {
    .home-promotions-slider {
      -ms-flex-direction: row;
          flex-direction: row;
      padding: 60px 0 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; } }
  .home-promotions-slider > * {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1; }
  .home-promotions-slider hgroup {
    height: 115px; }
    @media (min-width: 640px) {
      .home-promotions-slider hgroup {
        height: 100px; } }
  .home-promotions-slider .home-promotions-slider__carousel {
    position: relative;
    min-height: 300px;
    overflow: hidden; }
    .home-promotions-slider .home-promotions-slider__carousel .slick-track {
      left: 30px; }
      @media (min-width: 640px) {
        .home-promotions-slider .home-promotions-slider__carousel .slick-track {
          left: 0; } }
  .home-promotions-slider button.btn {
    display: block;
    margin: 30px auto; }
    @media (min-width: 640px) {
      .home-promotions-slider button.btn {
        display: none; } }
  .home-promotions-slider .home-promotions-slider__welcome {
    position: relative;
    display: block; }
    @media (min-width: 640px) {
      .home-promotions-slider .home-promotions-slider__welcome {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row; } }
  .home-promotions-slider .page-header {
    position: relative;
    padding: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    min-height: 90px; }
    @media (min-width: 640px) {
      .home-promotions-slider .page-header {
        margin-right: 15%;
        border-bottom: 3px solid #0b5846;
        text-align: right; } }
    @media (min-width: 640px) {
      .home-promotions-slider .page-header .page-header__subtitle {
        font-size: 16px; } }
    @media (min-width: 960px) {
      .home-promotions-slider .page-header .page-header__subtitle {
        font-size: 20px; } }
  .home-promotions-slider .home-promotions-slider__arrows {
    display: none; }
    @media (min-width: 640px) {
      .home-promotions-slider .home-promotions-slider__arrows {
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        left: 86%;
        -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
                transform: translateX(-100%);
        top: 120px;
        width: 60px; } }
    .home-promotions-slider .home-promotions-slider__arrows button {
      outline: none;
      border: 0;
      background: transparent;
      color: #0b5846;
      font-size: 30px;
      cursor: pointer; }
      .home-promotions-slider .home-promotions-slider__arrows button[disabled='disabled'], .home-promotions-slider .home-promotions-slider__arrows button:disabled {
        opacity: 0.2;
        cursor: initial; }
